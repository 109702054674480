/**
 * This is the central place where all possible tracking events must be defined.
 *
 * Naming conventions:
 *
 * We use the object-action framework for naming our events.
 *
 * Constant name (key): {OBJECT}_{ACTION}
 * Event name (value): {Object} {action}
 *
 * Example event: "Material downloaded"
 *    Material = Object (Proper Case)
 *    downloaded = action (always lowercase)
 *
 * Example event 2: "Material downloaded all"
 *    Material = Object (Proper Case)
 *    downloaded all = action (always lowercase)
 *
 * Example event 3: "Lead Status updated"
 *    Lead Status = Object (Proper Case)
 *    updated = action (always lowercase)
 *
 * More on naming conventions: https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 */

const trackingEvents = Object.freeze({
  CONTENT_CREATOR_OPENED: 'ContentCreator Opened',
  CONTENT_CREATOR_GENERATED: 'ContentCreator generated',
  CONTENT_CREATOR_DOWNLOADED: 'ContentCreator downloaded',
  CONTENT_CREATOR_SHARED: 'ContentCreator shared',

  LEAD_STATUS_UPDATED: 'Lead Status updated',
  MATERIAL_DOWNLOADED: 'Material downloaded',
  MATERIAL_DOWNLOADED_ALL: 'Material downloaded all',
  MATERIAL_DOWNLOADED_CAMPAIGN: 'Material downloaded campaign',
  LIGHT_PRODUCT_REGISTRATION_STARTED: 'LightProductRegistration Started',
  LIGHT_PRODUCT_REGISTRATION_SUCCEEDED: 'LightProductRegistration Succeeded',
  PRODUCT_REGISTRATION_SUCCEEDED: 'ProductRegistration Succeeded',
  ONBOARDING_PAYMENT_SUCCEEDED: 'OnboardingPaymentSucceeded',
  WEBSITE_PARSER_STARTED: 'WebsiteParserStarted',
  WEBSITE_PARSER_FINISHED: 'WebsiteParserFinished',
  CANCELATION_FEEDBACK_SEND: 'CancelationFeedbackSend',
  CANCELATION_DOWNGRADE_SKIPPED: 'CancelationDowngradeSkipped',
  CANCELATION_HELP_SKIPPED: 'CancelationHelpSkipped',
  CANCELATION_INFORMATION_SKIPPED: 'CancelationInformationSkipped',
  CANCELATION_TRIGGERED: 'CancelationTriggered',
  CANCELATION_SWITCHPLAN_SKIPPED: 'CancelationToFreeSkipped',
  CANCELATION_SWITCHPLAN_TRIGGERED: 'CancelationToFreeTriggered',
  UPDATE_LEAD_STATUS_SUCCEEDED: 'UpdateLeadStatusSucceeded',
  UPDATE_LEAD_POSITION_SUCCEEDED: 'UpdateLeadPositionSucceeded',

  // tracking Events for von Fred
  SUBSCRIPTION: 'Subscription',
  START_TRIAL: 'Start-trial',
  ADD_PAYMENT_INFO: 'Add Payment Info'

})

export default trackingEvents
